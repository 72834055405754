import React, { useState } from 'react';
import { TextStyle, ResourceItem, Button, Card, Stack } from '@shopify/polaris';
import styles from './ReportListItem.module.scss';
import { scannerBaseUrl } from 'utils/blockchain';
import { Blockchain } from 'types/Blockchain';
import useResendEmail, { ResendEmailValues } from 'hooks/useResendEmail';
import { MinusIcon, PlusIcon } from 'components/JsxIcons';

export type ReportListItemProps = {
  id: string;
  mintingTo: string | null;
  completedAt: string | null;
  ownerEmail: string;
  status?: 'RESERVED' | 'COMPLETE';
  isCustodial: boolean;
  isPredefined: boolean;
  transactionAddress: string | null;
  blockchain: Blockchain;
  mintingStarted: boolean;
  onSuccess: () => void;
  onFail: () => void;
  orderName: string | null;
  createdAt: string;
};

const ReportListItem: React.FC<ReportListItemProps> = ({
  id,
  ownerEmail,
  status,
  blockchain,
  transactionAddress,
  isCustodial,
  isPredefined,
  mintingStarted,
  onSuccess,
  onFail,
  orderName,
  createdAt,
  mintingTo,
  completedAt,
}) => {
  const { mutateAsync: resendEmail, isLoading } = useResendEmail();
  const [emailSent, setEmailSent] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const submitForm = async (values: ResendEmailValues) => {
    try {
      await resendEmail({
        shopNFTMintId: id,
        isCustodial: isCustodial,
        isPredefined: isPredefined,
      });
      onSuccess();
      setEmailSent(true);
    } catch (err) {
      onFail();
      setEmailSent(false);
    }
  };

  return (
    <>
      <ResourceItem id={id} onClick={() => setExpanded(!expanded)}>
        <div className={`${styles.row}`}>
          <div className="flex flex-row justify-center items-center">
            <TextStyle variation="strong">{ownerEmail || ''}</TextStyle>
            {status === 'COMPLETE' && (
              <TextStyle variation="subdued">
                <a
                  className={`${styles.column} ${styles['link-text']} ml-1`}
                  href={`${scannerBaseUrl(
                    blockchain
                  )}/tx/${transactionAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View claim details
                </a>
              </TextStyle>
            )}
          </div>
          <div className={`${styles.expander}`}>
            <div>
              {status === 'COMPLETE'
                ? 'Claimed'
                : mintingStarted
                ? 'Claiming'
                : 'Not Claimed'}
            </div>
            <div
              className={`${styles['px-8']}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div style={{ minWidth: '125px' }}>
                {' '}
                {/* Set min-width on the wrapper div */}
                <Button
                  fullWidth
                  disabled={status === 'COMPLETE' || mintingStarted}
                  onClick={() =>
                    submitForm({ shopNFTMintId: id, isCustodial, isPredefined })
                  }
                  loading={isLoading}
                >
                  {emailSent ? 'Sent' : 'Resend Email'}
                </Button>
              </div>
            </div>
            {expanded ? <MinusIcon /> : <PlusIcon />}
          </div>
        </div>
      </ResourceItem>
      {expanded && (
        <Card sectioned subdued>
          <div className={`${styles['row-at-tablet']}`}>
            <Stack vertical>
              {orderName && (
                <>
                  <Stack vertical>
                    <div className={`${styles.column}`}>
                      <TextStyle variation="subdued">Order Name</TextStyle>
                      {orderName}
                    </div>
                  </Stack>
                </>
              )}
              <div className={`${styles.column}`}>
                <TextStyle variation="subdued">Date purchased</TextStyle>
                <p>
                  {new Date(createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div>
            </Stack>

            <Stack vertical>
              {mintingTo && (
                <div className={`${styles.column}`}>
                  <TextStyle variation="subdued">Claimed by address</TextStyle>
                  <p>{mintingTo}</p>
                </div>
              )}
              {completedAt && (
                <div className={`${styles['max-w-sm']} ${styles.column}`}>
                  <TextStyle variation="subdued">Date claimed</TextStyle>
                  <p>
                    {new Date(completedAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </p>
                </div>
              )}
            </Stack>
          </div>
        </Card>
      )}
    </>
  );
};

export default ReportListItem;
