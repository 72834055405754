import * as React from 'react';

const ReportIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.74998 9.16667L3.04165 9.2075L5.70748 6.54167C5.65399 6.34578 5.65392 6.13915 5.70726 5.94323C5.7606 5.74731 5.86541 5.56924 6.01081 5.4275C6.46581 4.96667 7.20081 4.96667 7.65581 5.4275C7.96498 5.73083 8.06415 6.1625 7.95915 6.54167L9.45831 8.04083L9.74998 8C9.85498 8 9.95415 8 10.0416 8.04083L12.1241 5.95833C12.0833 5.87083 12.0833 5.77167 12.0833 5.66667C12.0833 5.35725 12.2062 5.0605 12.425 4.84171C12.6438 4.62292 12.9406 4.5 13.25 4.5C13.5594 4.5 13.8561 4.62292 14.0749 4.84171C14.2937 5.0605 14.4166 5.35725 14.4166 5.66667C14.4166 5.97609 14.2937 6.27283 14.0749 6.49162C13.8561 6.71042 13.5594 6.83333 13.25 6.83333C13.145 6.83333 13.0458 6.83333 12.9583 6.7925L10.8758 8.875C10.9166 8.9625 10.9166 9.06167 10.9166 9.16667C10.9166 9.47609 10.7937 9.77283 10.5749 9.99162C10.3561 10.2104 10.0594 10.3333 9.74998 10.3333C9.44056 10.3333 9.14381 10.2104 8.92502 9.99162C8.70623 9.77283 8.58331 9.47609 8.58331 9.16667L8.62415 8.875L7.12498 7.37583C6.93831 7.41667 6.72831 7.41667 6.54165 7.37583L3.87581 10.0417L3.91665 10.3333C3.91665 10.6428 3.79373 10.9395 3.57494 11.1583C3.35615 11.3771 3.0594 11.5 2.74998 11.5C2.44056 11.5 2.14381 11.3771 1.92502 11.1583C1.70623 10.9395 1.58331 10.6428 1.58331 10.3333C1.58331 10.0239 1.70623 9.72717 1.92502 9.50838C2.14381 9.28958 2.44056 9.16667 2.74998 9.16667Z"
      fill="currentColor"
    />
  </svg>
);

export default ReportIcon;
