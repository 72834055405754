import * as React from 'react';

const ReportIconLarge = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Grey circle background */}
    <circle cx="22" cy="22" r="22" fill="#E5E7EB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.56245 25.2083L8.36454 25.3206L15.6956 17.9896C15.5485 17.4509 15.5483 16.8827 15.695 16.3439C15.8417 15.8051 16.1299 15.3154 16.5297 14.9256C17.781 13.6583 19.8022 13.6583 21.0535 14.9256C21.9037 15.7598 22.1764 16.9469 21.8877 17.9896L26.0104 22.1123L26.8124 22C27.1012 22 27.3739 22 27.6144 22.1123L33.3413 16.3854C33.2291 16.1448 33.2291 15.8721 33.2291 15.5833C33.2291 14.7324 33.5671 13.9164 34.1688 13.3147C34.7705 12.713 35.5867 12.375 36.4375 12.375C37.2884 12.375 38.1043 12.713 38.706 13.3147C39.3077 13.9164 39.6457 14.7324 39.6457 15.5833C39.6457 16.4342 39.3077 17.2503 38.706 17.852C38.1043 18.4537 37.2884 18.7917 36.4375 18.7917C36.1488 18.7917 35.876 18.7917 35.6353 18.6794L29.9085 24.4062C30.0207 24.6469 30.0207 24.9196 30.0207 25.2083C30.0207 26.0592 29.6827 26.8753 29.081 27.477C28.4793 28.0786 27.6634 28.4166 26.8124 28.4166C25.9615 28.4166 25.1455 28.0786 24.5438 27.477C23.9421 26.8753 23.6041 26.0592 23.6041 25.2083L23.7164 24.4062L19.5937 20.2835C19.0804 20.3958 18.5029 20.3958 17.9895 20.2835L10.6585 27.6147L10.7708 28.4166C10.7708 29.2677 10.4328 30.0836 9.83109 30.6853C9.22941 31.287 8.41335 31.625 7.56245 31.625C6.71154 31.625 5.89548 31.287 5.29381 30.6853C4.69213 30.0836 4.3541 29.2677 4.3541 28.4166C4.3541 27.5657 4.69213 26.7497 5.29381 26.148C5.89548 25.5463 6.71154 25.2083 7.56245 25.2083Z"
      fill="#2C6ECB"
    />
  </svg>
);

export default ReportIconLarge;
