import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

export type ResendEmailValues = {
  shopNFTMintId: string;
  isCustodial: boolean;
  isPredefined: boolean;
};

export default function useResendEmail() {
  const queryCache = useQueryClient();

  return useMutation(
    async ({ shopNFTMintId, isCustodial, isPredefined }: ResendEmailValues) =>
      API()
        .post('/shopify/resendEmail/claimNFT', {
          shopNFTMintId: shopNFTMintId,
          isCustodial: isCustodial,
          isPredefined: isPredefined,
        })
        .then((res) => {
          return res.data;
        }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('email');
      },
    }
  );
}
