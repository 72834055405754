import Footer from 'components/Footer';
import React, { useEffect, useState } from 'react';
import { GetReportResponse } from 'hooks/useGetReport';
import {
  Banner,
  Card,
  Filters,
  Link,
  Page,
  ResourceList,
  TextStyle,
} from '@shopify/polaris';
import { SortOptions, SortType } from 'types/Utils';
import { determinePageSize, LocalPagination } from 'components/LocalPagination';
import ReportListItem from 'components/ReportListItem';
import styles from './NFTReportPage.module.scss';
import { useHistoryGoBack } from 'hooks/useHistoryGoBack';
import { Blockchain } from 'types/Blockchain';
import { orderBy } from 'lodash';
import ReportIconLarge from 'components/JsxIcons/Report/Report-Icon-large';

interface ReportPageProps {
  reportData: GetReportResponse[];
}

function getBlockchainAndAddressFromUrl(): {
  blockchain: string;
  address: string;
} | null {
  const url = window.location.href;

  const pattern = /report\/nft\/([^/]+)\/([0-9a-fA-Fx]+)/;

  const match = url.match(pattern);

  if (match && match.length >= 3) {
    const [, blockchain, address] = match;
    return { blockchain, address };
  }

  return null;
}

const NFTReportPage: React.FC<ReportPageProps> = ({ reportData }) => {
  const result = getBlockchainAndAddressFromUrl();
  const historyGoBack = useHistoryGoBack();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const numberOfPages = Math.ceil((reportData?.length ?? 0) / pageSize);
  const [reportsAvailable, setReportsAvailable] =
    useState<GetReportResponse[]>(reportData);
  const [reportsAvailableAndSorted, setReportsAvailableAndSorted] =
    useState<GetReportResponse[]>(reportData);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const isEmptyState = reportData?.length === 0;

  const [showSuccess, setShowSuccess] = useState(false);

  const handleSuccess = () => {
    setShowSuccess(true);
  };

  const [showFail, setShowFail] = useState(false);

  const handleFail = (requestId?: string) => {
    setShowFail(true);
  };

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setReportsAvailable(
        reportData.filter(
          (report) =>
            report.ownerEmail
              ?.toLowerCase()
              .includes(queryValue.toLowerCase()) ||
            report.orderName?.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setReportsAvailable(reportData);
    }
  }, [queryValue, reportData]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, (r) => r.ownerEmail.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, 'report.createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, 'report.updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, reportsAvailable]);

  const paginatedItems = determinePageSize(
    reportsAvailableAndSorted,
    currentPage,
    pageSize,
    numberOfPages
  );

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: `Report`,
            onAction: () => historyGoBack('/drops'),
          },
        ]}
        title={`Report`}
      >
        {showSuccess && (
          <div style={{ paddingBottom: '1rem' }}>
            <Banner
              title="Email successfully sent"
              status="success"
              onDismiss={() => setShowSuccess(false)}
            />
          </div>
        )}
        {showFail && (
          <div style={{ paddingBottom: '1rem' }}>
            <Banner
              title="Email failed to send"
              status="critical"
              onDismiss={() => setShowFail(false)}
            >
              <p>
                There was an error when trying to resend the email. If the issue
                persists, please contact support@verisart.com
              </p>
            </Banner>
          </div>
        )}
        {!isEmptyState ? (
          <>
            <Card>
              <ResourceList
                sortValue={sortValue}
                sortOptions={SortOptions}
                onSortChange={(selected: SortType) => {
                  setSortValue(selected);
                }}
                filterControl={filterControl}
                items={paginatedItems}
                renderItem={(item: GetReportResponse) => (
                  <>
                    <ReportListItem
                      id={item.id}
                      mintingTo={item.mintingTo}
                      completedAt={item.completedAt}
                      ownerEmail={item.ownerEmail ?? ''}
                      status={item.status}
                      isCustodial={item.isCustodial}
                      isPredefined={item.isPredefined}
                      transactionAddress={item.transactionAddress}
                      blockchain={result?.blockchain as Blockchain}
                      mintingStarted={item.mintingStarted}
                      onSuccess={handleSuccess}
                      onFail={handleFail}
                      orderName={item.orderName}
                      createdAt={item.createdAt}
                    />
                  </>
                )}
              />
            </Card>
            <div className={styles.pagination}>
              <LocalPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={numberOfPages}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '25vh',
            }}
          >
            <div
              style={{
                maxWidth: '400px',
                width: '100%',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <Card>
                <div style={{ padding: '20px' }}>
                  <div style={{ marginBottom: '1rem', marginTop: '3rem' }}>
                    <ReportIconLarge />
                  </div>
                  <div style={{ fontSize: '18px', marginBottom: '2rem' }}>
                    <TextStyle variation="strong">
                      No reports for this contract
                    </TextStyle>
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '4rem' }}>
                    <TextStyle variation="subdued">
                      Reports for contracts will appear here. Once a product has
                      been purchased using this contract address, you can view
                      the reporting on who has claimed the product and who has
                      not.
                    </TextStyle>
                  </div>
                  <div style={{ marginBottom: '5rem' }}>
                    <Link
                      removeUnderline
                      url="/drops"
                      onClick={() => historyGoBack('/drops')}
                    >
                      Back to NFT drops
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}
      </Page>
      <Footer />
    </>
  );
};

export default NFTReportPage;
