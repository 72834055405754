import React from 'react';
import LoadingPage from '../../auth/LoadingPage';
import useGetDropReport from 'hooks/useGetReport';
import NFTReportPage from 'pages/ReportsPage/NFTReportPage';
import { useParams } from 'react-router';
import { Blockchain } from 'types/Blockchain';
export const ReportPageLoader: React.FC = () => {
  const { blockchain, address } =
    useParams<{ blockchain: Blockchain; address: string }>();

  const { data } = useGetDropReport(blockchain, address);

  if (data === undefined) return <LoadingPage />;

  return <NFTReportPage reportData={data} />;
};
