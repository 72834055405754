import React from 'react';
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
  Redirect,
} from 'react-router-dom';

import DashboardPage from './pages/DashboardPage';
import CreatorsPage from './pages/CreatorsPage';
import ContractsListPage from './pages/ContractsListPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import AdminLink from './pages/AdminLink';
import OnboardingPage from './pages/OnboardingPage';
import useShop from './hooks/useShop';
import LoadingPage from './auth/LoadingPage';
import useSession, {
  isAccountCreated,
  isAccountSessionLoaded,
} from './hooks/useSession';
import { PlanType } from './utils/plans';
import ConnectedPricingPage from './pages/ConnectedPricingPage';
import MetafieldFormLoader from 'pages/ProductForm/MetafieldFormLoader';
import TokenGateFormPage from 'pages/TokenGateFormPage';
import CreateContractsFormPage from 'pages/CreateContractsFormPage';
import TokenGatePage from 'pages/TokenGatePage';
import EditTokenGateFormPage from 'pages/EditTokenGateFormPage';
import EditPrintFormPage from 'pages/EditPrintFormPage';
import { RoyaltySplitPageLoader } from './pages/RoyaltySplit/RoyaltySplitPageLoader';
import { RoyaltySplitPageCreateLoader } from './pages/RoyaltySplit/RoyaltySplitPageCreateLoader';
import EditContractsFormPage from 'pages/EditContractsFormPage';
import RoyaltySplitPageEditLoader from './pages/RoyaltySplit/RoyaltySplitPageEditLoader';
import SettingsPage, { CustomizationSettingsPage } from './pages/SettingsPage';
import useGetCapabilities, { Capability } from './hooks/useGetCapabilities';
import { FixSubscriptionPage } from './pages/FixSubscription/FixSubscriptionPage';
import AdvancedEmailPage, {
  TestEmailType,
} from 'pages/SettingsPage/AdvancedEmailPage';
import EmailGlobalCustomization from 'pages/SettingsPage/EmailGlobalCustomization';
import SelfCreatorDetailsPage from './pages/OnboardingPage/SelfCreatorDetailsPage';
import { DropPageLoader } from './pages/DropPage/DropPageLoader';
import CreateDropPage from 'pages/DropFormPage/CreateDropPage';
import EditDropPage from './pages/DropFormPage/EditDropPage';
import CertificateList from './pages/CertificateList';
import PrintsPage from 'pages/PrintsPage';
import PrintsFormPage from 'pages/PrintsFormPage';
import { ReportPageLoader } from 'components/ReportListItem/ReportPageLoader';

const ONBOARDING_PATH = '/onboarding';
export const PLAN_PATH = '/plan';

export const TOKEN_GATES_PATH = '/tokengates';
export const PRINTS_PATH = '/prints';
export const CREATORS_PATH = '/creators';
export const CONTRACTS_PATH = '/contracts';

export const SELF_CREATOR_DETAILS_PATH = '/creators/self';
export const ROYALTY_SPLITS_PATH = '/royalty-splits';
export const SETTINGS_PATH = '/settings';
export const CUSTOMIZATIONS_EMAIL_COA_PATH = '/customizations/claimcoa';
export const CUSTOMIZATIONS_EMAIL_NFT_PATH = '/customizations/claimnft';
export const CUSTOMIZATIONS_EMAIL_VIEW_NFT_PATH = '/customizations/viewnft';
export const CUSTOMIZATIONS_CERTIFICATE_CUSTOMIZATION_PATH = '/customizations';
export const SETTINGS_EMAIL_GLOBAL_PATH = '/customizations/global';
export const NFT_REPORTS_PATH = '/report/nft';

const OnboardingRequiredRoutes: React.FC = ({ children }) => {
  const accountCreated = useSession(isAccountCreated);
  const accountSessionLoaded = useSession(isAccountSessionLoaded);
  const { isLoading, data } = useShop({
    enabled: accountSessionLoaded && accountCreated,
  });
  const onboardingRequired = !data?.shop?.accountId;

  if (!accountSessionLoaded || isLoading) {
    return <LoadingPage />;
  }

  if (!accountCreated || onboardingRequired) {
    return <Redirect to={ONBOARDING_PATH} />;
  }

  return (
    <Switch>
      {children}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

const ActivePlanRequiredRoutes: React.FC = ({ children }) => {
  const accountCreated = useSession(isAccountCreated);
  const accountSessionLoaded = useSession(isAccountSessionLoaded);
  const { isLoading, data } = useShop({
    enabled: accountSessionLoaded && accountCreated,
  });
  const planActive = data?.shop?.planType !== PlanType.FREE;

  if (!accountSessionLoaded || isLoading) {
    return <LoadingPage />;
  }

  if (!planActive) {
    return <Redirect to={PLAN_PATH} />;
  }

  return (
    <Switch>
      {children}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

const CapabilityRequiredRoute: React.FC<{
  capability: Capability;
  path: string;
  redirect: string;
  exact?: boolean;
}> = ({ capability, children, path, redirect, exact }) => {
  const { data } = useGetCapabilities();

  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (!data) {
          return <LoadingPage />;
        }

        if (data.capabilities.indexOf(capability) !== -1) {
          return children;
        }

        return <Redirect to={redirect} />;
      }}
    />
  );
};

function AppRoutes(props: RouteComponentProps) {
  return (
    <Switch>
      <Route exact path={ONBOARDING_PATH}>
        <OnboardingPage />
      </Route>
      <OnboardingRequiredRoutes>
        <Route exact path={PLAN_PATH}>
          <ConnectedPricingPage />
        </Route>
        <Route path="/fixSubscription">
          <FixSubscriptionPage />
        </Route>
        <Route exact path={SELF_CREATOR_DETAILS_PATH}>
          <SelfCreatorDetailsPage />
        </Route>
        <ActivePlanRequiredRoutes>
          <Route exact path="/">
            <DashboardPage />
          </Route>
          <Route exact path="/nft">
            <Redirect to="/drops" />
          </Route>
          <Route path="/product-metadata/:id/:defaultType?">
            <MetafieldFormLoader isVariant={false} />
          </Route>
          <Route path="/variant-metadata/:id/:defaultType?">
            <MetafieldFormLoader isVariant={true} />
          </Route>
          <Route exact path={SETTINGS_PATH}>
            <SettingsPage />
          </Route>
          <Route exact path={CUSTOMIZATIONS_CERTIFICATE_CUSTOMIZATION_PATH}>
            <CustomizationSettingsPage />
          </Route>
          <CapabilityRequiredRoute
            capability={'emails.customize'}
            path={SETTINGS_EMAIL_GLOBAL_PATH}
            redirect="/customizations"
          >
            <EmailGlobalCustomization />
          </CapabilityRequiredRoute>
          <CapabilityRequiredRoute
            capability={'emails.customize'}
            path={CUSTOMIZATIONS_EMAIL_COA_PATH}
            redirect="/customizations"
          >
            <AdvancedEmailPage type={TestEmailType.CERTIFICATE} />
          </CapabilityRequiredRoute>
          <CapabilityRequiredRoute
            capability={'emails.customize'}
            path={CUSTOMIZATIONS_EMAIL_NFT_PATH}
            redirect="/customizations"
          >
            <AdvancedEmailPage type={TestEmailType.NFT_CLAIM} />
          </CapabilityRequiredRoute>
          <CapabilityRequiredRoute
            capability={'emails.customize'}
            path={CUSTOMIZATIONS_EMAIL_VIEW_NFT_PATH}
            redirect="/customizations"
          >
            <AdvancedEmailPage type={TestEmailType.NFT_MINTED} />
          </CapabilityRequiredRoute>
          <Route exact path={ROYALTY_SPLITS_PATH}>
            <RoyaltySplitPageLoader />
          </Route>
          <Route exact path="/royalty-splits-create">
            <RoyaltySplitPageCreateLoader />
          </Route>
          <Route exact path={'/royalty-splits-edit/:id'}>
            <RoyaltySplitPageEditLoader />
          </Route>
          <Route exact path={CREATORS_PATH}>
            <CreatorsPage />
          </Route>
          <Route exact path={TOKEN_GATES_PATH}>
            <TokenGatePage />
          </Route>
          <Route exact path="/certificate">
            <CertificateList />
          </Route>
          <Route exact path={PRINTS_PATH}>
            <PrintsPage />
          </Route>
          <Route exact path={`${PRINTS_PATH}/create`}>
            <PrintsFormPage />
          </Route>
          <Route exact path={`${PRINTS_PATH}/edit/:id`}>
            <EditPrintFormPage />
          </Route>
          <Route exact path={`${TOKEN_GATES_PATH}/create`}>
            <TokenGateFormPage />
          </Route>
          <Route exact path={`${TOKEN_GATES_PATH}/edit/:id`}>
            <EditTokenGateFormPage />
          </Route>
          <Route exact path={CONTRACTS_PATH}>
            <ContractsListPage />
          </Route>
          <Route exact path="/contracts/create">
            <CreateContractsFormPage />
          </Route>
          <Route exact path="/contracts/edit/:id">
            <EditContractsFormPage />
          </Route>
          <Route exact path="/drops">
            <DropPageLoader />
          </Route>
          <Route exact path="/drops/create">
            <CreateDropPage />
          </Route>
          <Route exact path="/drops/edit/:id">
            <EditDropPage />
          </Route>
          <Route exact path="/report/nft/:blockchain/:address">
            <ReportPageLoader />
          </Route>
          <Route exact path="/admin/:type/edit">
            <AdminLink />
          </Route>
        </ActivePlanRequiredRoutes>
      </OnboardingRequiredRoutes>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default withRouter(AppRoutes);
